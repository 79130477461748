import './app.css';
import './components/nav/nav.css';
import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Home from './components/home/home';
import About from './components/about/about';
import Projects from './components/projects/projects';
import Contact from './components/contact/contact';

const App = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const [homeRef, homeInView, homeEntry] = useInView({ threshold: .8, rootMargin: '200px' });
  const [aboutRef, aboutInView, aboutEntry] = useInView({ threshold: .8, rootMargin: '200px' });
  const [projectsRef, projectsInView, projectsEntry] = useInView({ threshold: .8, rootMargin: '200px' });
  const [contactRef, contactInView, contactEntry] = useInView({ threshold: .8, rootMargin: '200px' });
  const subNav = useRef(null);

  useEffect(() => {
      if (homeEntry && homeEntry.isIntersecting) {
        document.querySelector('.home-down').style.opacity = 1;
      } else {
        document.querySelector('.home-down').style.opacity = 0;
      }
      if (aboutEntry && aboutEntry.isIntersecting) {
        setTimeout(() => {
          document.querySelector('.about-down').style.opacity = 1;
        }, 4000);
      } else {
        document.querySelector('.about-down').style.opacity = 0;
      }
      if (projectsEntry && projectsEntry.isIntersecting) {
        setTimeout(() => {
          document.querySelector('.projects-down').style.opacity = 1;
        }, 4000);
      } else {
        document.querySelector('.projects-down').style.opacity = 0;
      }
      if (contactEntry && contactEntry.isIntersecting) {
        setTimeout(() => {
          document.querySelector('.contact-up').style.opacity = 1;
          document.querySelector('#back-to-top').style.opacity = 1;
        }, 4000)
      } else {
        document.querySelector('.contact-up').style.opacity = 0;
        document.querySelector('#back-to-top').style.opacity = 0;
      }
  }, [aboutInView, homeInView, projectsInView, contactInView])

  useEffect(() => {
    const openNav = (e) => {
      if (window.innerWidth > 600) {
        if (e.clientY < 200) {
          setNavOpen(true);
          subNav.current.style.opacity = '1';
          subNav.current.style.right = '0px'
        } else {
          setNavOpen(false);
          subNav.current.style.opacity = '0';
          subNav.current.style.right = '-100px';
        }
      }
    }
    window.addEventListener('mousemove', openNav);
    return () => {
      window.removeEventListener('mousemove', openNav);
    }
  },[])

  const toggleNav = (e) => {
    e.preventDefault();
    if (!mobileNavOpen) {
      subNav.current.style.opacity = '1';
      subNav.current.style.right = '0px'
    } else {
      subNav.current.style.opacity = '0';
      subNav.current.style.right = '-200px';
    }
    setMobileNavOpen(!mobileNavOpen);
  }

  const redirect = (e, term) => {
    e.preventDefault();
    if (term) {
      switch (term) {
        case '' || 'home':
          window.innerWidth > 600
          ? homeEntry.target.scrollIntoView()
          : homeEntry.target.scrollIntoView({ behavior: 'smooth' }); 
          setNavOpen(false);
          break;
        case 'about':
          window.innerWidth > 600
          ? aboutEntry.target.scrollIntoView()
          : aboutEntry.target.scrollIntoView({ behavior: 'smooth' });
          setNavOpen(false);
          break;
        case 'projects':
          window.innerWidth > 600
          ? projectsEntry.target.scrollIntoView()
          : projectsEntry.target.scrollIntoView({ behavior: 'smooth' });
          setNavOpen(false);
          break;
        case 'contact':
          window.innerWidth > 600
          ? contactEntry.target.scrollIntoView()
          : contactEntry.target.scrollIntoView({ behavior: 'smooth' });
          setNavOpen(false);
          break;
        default:
          return;
      }
    } else {
      switch (e.target.id) {
        case '' || 'home':
          window.innerWidth > 600
          ? homeEntry.target.scrollIntoView()
          : homeEntry.target.scrollIntoView({ behavior: 'smooth' }); 
          setNavOpen(false);
          break;
        case 'about':
          window.innerWidth > 600
          ? aboutEntry.target.scrollIntoView()
          : aboutEntry.target.scrollIntoView({ behavior: 'smooth' });
          setNavOpen(false);
          break;
        case 'projects':
          window.innerWidth > 600
          ? projectsEntry.target.scrollIntoView()
          : projectsEntry.target.scrollIntoView({ behavior: 'smooth' });
          setNavOpen(false);
          break;
        case 'contact':
          window.innerWidth > 600
          ? contactEntry.target.scrollIntoView()
          : contactEntry.target.scrollIntoView({ behavior: 'smooth' });
          setNavOpen(false);
          break;
        default:
          return;
      }
    }
    if (mobileNavOpen) {
      setNavOpen(false);
      setMobileNavOpen(false);
      subNav.current.style.opacity = '0';
      subNav.current.style.right = '-100px';
    }
  }

  return (
    <div className='app-container'>
      <nav>
        <div>
          <h1 id='nav-desktop-home'>
            <a href='' onClick={e => {
              e.preventDefault();
              homeEntry.target.scrollIntoView({ behavior: 'smooth' })
            }
            }>
              NC
            </a>
          </h1>
          <h1 id='nav-mobile-home'>
          <a href='' onClick={e => {
              e.preventDefault();
              homeEntry.target.scrollIntoView({ behavior: 'smooth' })
            }
            }>
              NC
            </a>
          </h1>
        </div>

        <div className='hamburger-links'>
          <div ref={subNav} className='nav-links'>

            <h2>
              <a href='#about' id='about' onClick={redirect}>About</a>
            </h2>
            <h2>
              <a href='#projects' id='projects' onClick={redirect}>Projects</a>
            </h2>
            <h2>
              <a href='#contact' id='contact' onClick={redirect}>Contact</a>
            </h2>
          </div>

          <button className={navOpen || mobileNavOpen ? 'hamburger--spin is-active' : 'hamburger--spin'} type="button" onClick={window.innerWidth < 600 ? (e) => toggleNav(e) : () => {}} style={{ border: 'none', backgroundColor: 'none' }}>
            <span className="hamburger-box" style={{ backgroundColor: 'none' }}>
              <span className="hamburger-inner" style={{ backgroundColor: 'none' }}></span>
            </span>
          </button> 
        </div>

      </nav>
      <div className='container'>
        <div ref={homeRef} id='page-one'>
          <a href=''></a>
          <Home redirect={redirect} />
        </div>
        <div ref={aboutRef} id='page-two'>
          <a href='#about'></a>
          <About redirect={redirect} />
        </div>
        <div ref={projectsRef} id='page-three'>
          <a href='#projects'></a>
          <Projects redirect={redirect} />
        </div>
        <div ref={contactRef} id='page-four'>
          <a href='#contact'></a>
          <Contact redirect={redirect} />
        </div>
      </div>
    </div>
  );
};

export default App;