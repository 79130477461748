import './about.css';
import React from 'react';
import jsLogo from '../../jsLogo.png';
import typescriptLogo from '../../typescriptLogo.png';
import nodeLogo from '../../nodeLogo.png';
import cssLogo from '../../cssLogo.png';
import htmlLogo from '../../htmlLogo.png';
import reactLogo from '../../reactLogo.png';
import reduxLogo from '../../reduxLogo.png';
import gitIcon from '../../gitIcon.png';
import webpackLogo from '../../webpackLogo.png';

const Slides = () => {
  return (
    <div className='slider'>
    <div className='slide-track'>
      <div className='slide' style={{ paddingTop: '4rem', bottom: '-4rem' }}>
        <img src={nodeLogo} className='slide-image' alt='Node JS' />
      </div>
      <div className='slide'>
        <img src={jsLogo} alt='' className='slide-image' alt='Javascript' />
      </div>
      <div className='slide'>
        <img src={typescriptLogo} className='slide-image' alt='Typescript' />
      </div>
      <div className='slide'>
        <img src={reactLogo} className='slide-image' alt='React' />
      </div>
      <div className='slide'>
        <img src={reduxLogo} className='slide-image' alt='Redux' />
      </div>
      <div className='slide'>
        <img src={htmlLogo} className='slide-image' alt='HTML' />
      </div>
      <div className='slide'>
        <img src={cssLogo} className='slide-image css' alt='CSS' />
      </div>
      <div className='slide'>
        <img src={gitIcon} className='slide-image' alt='Git' />
      </div>
      <div className='slide'>
        <img src={webpackLogo} className='slide-image' alt='Webpack' />
      </div>
      <div className='slide' style={{ paddingTop: '4rem', bottom: '-4rem' }}>
        <img src={nodeLogo} className='slide-image' alt='Node JS' />
      </div>
      <div className='slide'>
        <img src={jsLogo} alt='' className='slide-image' alt='Javascript' />
      </div>
      <div className='slide'>
        <img src={typescriptLogo} className='slide-image' alt='Typescript' />
      </div>
      <div className='slide'>
        <img src={reactLogo} className='slide-image' alt='React' />
      </div>
      <div className='slide'>
        <img src={reduxLogo} className='slide-image' alt='Redux' />
      </div>
      <div className='slide'>
        <img src={htmlLogo} className='slide-image' alt='HTML' />
      </div>
      <div className='slide'>
        <img src={cssLogo} className='slide-image css' alt='CSS' />
      </div>
      <div className='slide'>
        <img src={gitIcon} className='slide-image' alt='Git' />
      </div>
      <div className='slide'>
        <img src={webpackLogo} className='slide-image' alt='Webpack' />
      </div>
    </div>
  </div>
  );
};

export default Slides;
