import './about.css';
import React from 'react';
import Slides from './slides';
import { MdExpandMore } from 'react-icons/md';
import { GrDocumentPdf } from 'react-icons/gr';
import NickCarr_Resume from '../../NickCarr_Resume.pdf';

const About = ({ redirect }) => {

  const goDown = e => {
    e.preventDefault();
    if ('ontouchstart' in document.documentElement) return false;
    redirect(e, 'projects');
  }
  return (
    <div className='about-container'>
      <div className='about-slide-panel'>
        <Slides />
      </div>
      <div className='about-text-panel'>
        <h1>About Me</h1>
        <p>My name is Nick Carr, I'm a freelance full-stack web developer and instructional associate at Fullstack Academy.  In my past life, I worked in various roles in the TV/film industry for shows on HBO, Starz, Neftlix, and more.  I am a musician, DJ, and have a passion for combining my love of music with technology.</p>
        <div className='resume-link'>
          <a href={NickCarr_Resume} target='_blank' rel='noreferrer'>View My Resume</a>
          <GrDocumentPdf />
        </div>
      </div>
      <div
          onClick={goDown}
          onTouchStart={e => e.preventDefault()}
          onMouseDown={e => e.preventDefault()}
          onTouchEnd={e => e.preventDefault()}
          className='about-down'
        >
          <MdExpandMore 
              style={{ 
                filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.24))'
              }}
            />
        </div>
    </div>
  );
};

export default About;
