import './contact.css';
import React from 'react';
import gmail from '../../gmail.svg';
import linkedin from '../../linkedin.svg';
import github from '../../github.svg';
import { MdExpandLess } from 'react-icons/md';

const Contact = ({ redirect }) => {
  
  const goUp = e => {
    e.preventDefault();
    redirect(e, 'home');
  }
  return (
    <div className='contact-container'>
      <h1>Let's get in touch!</h1>
      <div className='contact-links'>
        <a href='mailto: nick.carr84@gmail.com'>
          <img src={gmail} alt='gmail' />
        </a>
        <a href='https://www.linkedin.com/in/nickcarr51/' target='_blank' rel='noreferrer'>
          <img src={linkedin} alt='linkedin' />
        </a>
        <a href='https://github.com/nickcarr51' target='_blank' rel='noreferrer'> 
          <img src={github} alt='github' />
        </a>
      </div>
      <div
        onClick={goUp}
        className='contact-up'
      >
        <MdExpandLess
          style={{
            filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.24))'
          }}
        />

      </div>
      <h2 id='back-to-top'>Back To Top</h2>
    </div>
  );
};

export default Contact;
