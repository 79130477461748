import './home.css';
import React from 'react';
import gitHubPicWeb from '../../gitHubPicWeb.jpg';
import { MdExpandMore } from 'react-icons/md';

const Home = ({ redirect }) => {

  const goDown = e => {
    e.preventDefault();
    if ("ontouchstart" in document.documentElement) return false;
    redirect(e, 'about');
  }
  return (
    <div className='home-container'>
      <div className='home-image-panel'>
        <img src={gitHubPicWeb} alt='Nick Carr' />
      </div>
      <div className='home-text-panel'>
        <div className='home-text-main'>
          <h1 id='hi'>Hi,</h1>
          {' '}
          <h1 id='name'>I'm Nick</h1>
        </div>
        <div className='home-text-secondary'>
          <h1>Fullstack/Front-end web developer</h1>
        </div>
      </div>
      <div 
        onClick={goDown}
        onTouchStart={e => e.preventDefault()}
        onMouseDown={e => e.preventDefault()} 
        onTouchEnd={e => e.preventDefault()} 
        className='home-down'
      >
        <MdExpandMore
          style={{ 
            filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.24))'
          }} 
        />
      </div>
    </div>
  );
};

export default Home;
