import BuddyLoopsWeb from '../../BuddyLoopsWeb.jpg';
import skrapWeb from '../../skrapWeb.jpg';
import digInKeyWeb from '../../digInKeyWeb.jpg';
import ThrivingInGradSchoolWeb from '../../ThrivingInGradSchoolWeb.jpg';

export const projects = [
  {
    name: 'Buddy Loops',
    image: BuddyLoopsWeb,
    URL: 'https://buddyloops.com/',
    bio: 'BuddyLoops is a real-time, collaborative, loop-based, musical instrument made to enable easy musical collaboration over the internet. This project is an ongoing work in progress that is being continually updated.',
    video: 'https://youtu.be/n7B5eQSpOto',
  },
  {
    name: 'Dig In Key',
    image: digInKeyWeb,
    URL: 'https://diginkey.herokuapp.com/',
    bio: 'Dig In Key is a utility app for DJs that allows you to search for similar tracks based on tempo and musical key.  Play samples of tracks, create and analyze your playlist, and login with Spotify to save your playlist.  My stackathon project at Fullstack Academy.'
  },
  {
    name: 'Thriving In Graduate School',
    image: ThrivingInGradSchoolWeb,
    URL: 'https://www.thrivingingradschool.com/',
    bio: 'A promotional website for the book, "Thriving In Grad School: The Experts Guide to Success and Wellness'
  },
  {
    name: 'Skräp',
    image: skrapWeb,
    URL: 'https://skraplitter.herokuapp.com/',
    bio: 'Skräp is a community-service focused social media app that allows users to pledge money towards local clean-ups, or earn money working at clean-up sites.  My capstone project at Fullstack Academy.',
  }
]