import './projects.css'
import React from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { SiYoutube } from 'react-icons/si';

const ProjectPage = React.forwardRef((props, ref) => {
  const { project } = props;
  return (
    <div className='single-project-page' ref={ref}>
      <div className='project-image'>
        <img src={project.image} alt='buddyloops.com' />
      </div>
      <div className='project-description'>
        <div className='project-description-container'>
          <h1>{project.name}</h1>
          <p>{project.bio}</p>
          <a href={project.URL} target='_blank' rel='noreferrer'>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '.53rem' }}>
              <p>{project.name}</p>
              <BiLinkExternal style={{ verticalAlign: 'center', margin: '0 .5rem' }} />
            </div>
          </a>
          {
            project.video && 
            <a href={project.video} target='_blank' rel='noreferrer'>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '.53rem' }}>
                <p style={{ margin: '0'}}>{project.name} Demo</p>
                <SiYoutube style={{ verticalAlign: 'center', margin: '0 .5rem' }} />
              </div>
            </a>
          }
        </div>
      </div>
    </div>
  );
});

export default ProjectPage;
