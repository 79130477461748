import './projects.css';
import React, { useState, useEffect } from 'react';
import ProjectPage from './projectPage';
import { projects } from './projectData';
import { MdExpandMore } from 'react-icons/md';
import { useInView } from 'react-intersection-observer';

const Projects = ({ redirect }) => {
  const [page1, page1InView, page1Entry] = useInView({ threshold: .8, rootMargin: '200px' });
  const [page2, page2InView, page2Entry] = useInView({ threshold: .8, rootMargin: '200px' });
  const [page3, page3InView, page3Entry] = useInView({ threshold: .8, rootMargin: '200px' });
  const [page4, page4InView, page4Entry] = useInView({ threshold: .8, rootMargin: '200px' });

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  useEffect(() => {
    if (page1Entry && page1Entry.isIntersecting) {
      setShowLeftArrow(false);
      !showRightArrow && setShowRightArrow(true);
    } else if (page4Entry && page4Entry.isIntersecting) {
      setShowRightArrow(false);
      !showLeftArrow && setShowLeftArrow(true);
    } else {
      !showLeftArrow && setShowLeftArrow(true);
      !showRightArrow && setShowRightArrow(true);
    }
  }, [page1InView, page2InView, page3InView, page4InView]);

  const goDown = e => {
    e.preventDefault();
    if ('ontouchstart' in document.documentElement) return false;
    redirect(e, 'contact');
  }

  const refs = [page1, page2, page3, page4];

  const goRight = e => {
    e.preventDefault();
    if ('ontouchstart' in document.documentElement) return false;
    if (page1Entry && page1Entry.isIntersecting) {
      page2Entry.target.scrollIntoView();
    }
    if (page2Entry && page2Entry.isIntersecting) {
      page3Entry.target.scrollIntoView();
    }
    if (page3Entry && page3Entry.isIntersecting) {
      page4Entry.target.scrollIntoView()
    }
  }

  const goLeft = e => {
    e.preventDefault();
    if ('ontouchstart' in document.documentElement) return false;
    if (page2Entry && page2Entry.isIntersecting) {
      page1Entry.target.scrollIntoView();
    }
    if (page3Entry && page3Entry.isIntersecting) {
      page2Entry.target.scrollIntoView();
    }
    if (page4Entry && page4Entry.isIntersecting) {
      page3Entry.target.scrollIntoView();
    }
  }

  return (
    <div className='projects-container'>
      <div id="carousel" className="snap">
        {
          projects.map((p, i, arr) => 
            <div key={i}>
              <ProjectPage project={p} idx={i} arr={arr} ref={refs[i]} />
            </div>
          )
        }
      </div>
      <div
        onClick={goLeft}
        onMouseDown={e => e.preventDefault()}
        onTouchStart={e => e.preventDefault()}
        onTouchEnd={e => e.preventDefault()}
        className={showLeftArrow ? 'project-left-arrow' : 'project-left-arrow hidden'}
      >
        <MdExpandMore 
          style={{ 
            filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.24))'
          }}
        />
      </div>
      <div
        onClick={goRight}
        onMouseDown={e => e.preventDefault()}
        onTouchStart={e => e.preventDefault()}
        onTouchEnd={e => e.preventDefault()}
        className={showRightArrow ? 'project-right-arrow' : 'project-right-arrow hidden'}
      >
        <MdExpandMore 
          style={{ 
            filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.24))'
          }}
        />
      </div>
      <div 
        onClick={goDown}
        onTouchStart={e => e.preventDefault()}
        onMouseDown={e => e.preventDefault()}
        onTouchEnd={e => e.preventDefault()}
        className='projects-down'
        style={{
          opacity: 0,
        }}
      >
        <MdExpandMore 
            style={{ 
              filter: 'drop-shadow(0 4px 4px rgba(0,0,0,0.24))'
            }}
          />
      </div>
    </div>
  );
};

export default Projects;
